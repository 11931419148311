<template>
    <default-app-template>
        <div class="flex justify-between">
            <basic-headline class="text-center">Results</basic-headline>
            <router-link
                :to="{
                    name: 'Dashboard',
                    params: { patientId: patient.patientId },
                }"
                class="no-underline font-bold text-primary-700 mt-5"
            >
                <font-awesome-icon
                    :icon="['fas', 'arrow-circle-left']"
                    class="mr-2"
                />
                Back to Dashboard
            </router-link>
        </div>
        <template v-if="getStatus === status.complete">
            <ResultsComplete />
        </template>

        <template v-else-if="getStatus === status.waiting">
            <ResultsLoadingScreen />
        </template>

        <template v-else-if="getStatus === status.fail">
            <p
                class="text-gray-700 text-xl py-3 text-center"
                data-cypress="results.error"
            >
                Error analyzing the data.
            </p>
            <basic-notification type="error" class="mb-10">
                <template v-slot:bold>
                    Error: {{ measurement.status.code }}
                </template>
                {{ measurement.status.message }}
            </basic-notification>
            <!--/ ACTIONS -->
            <MeasurementToolbar :uuid="measurement.uuid" />
            <!-- / ACTIONS -->
        </template>
        <template v-else>
            <p class="text-gray-700 text-xl py-3 text-center">
                This measurement is incomplete or deleted. Please start over.
            </p>
            <!--/ ACTIONS -->
            <MeasurementToolbar />
            <!-- / ACTIONS -->
        </template>
    </default-app-template>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { status } from 'helpers/constants'

export default {
    computed: {
        ...mapState('measurement', {
            measurement: state => state.active,
        }),
        ...mapState('patient', {
            patient: state => state.currentPatient,
        }),
        ...mapGetters('measurement', ['getStatus']),
        status: function() {
            return status
        },
    },

    created() {
        this.subscribe()
    },
    beforeDestroy() {
        this.unsubscribe()
    },
    methods: {
        ...mapActions('measurement', ['subscribe', 'unsubscribe']),
    },
}
</script>
